import axios from 'axios';

export function getCartJson() {
  return axios({
    method: 'post',
    url: '/inshop/scripts/shop.aspx?action=showshoppingcart&LayoutNotIncluded=1&ajax=1',
    config: { headers: {'Content-Type': 'multipart/form-data' }}
  })
}

export function recalculateCart(CartItems) {
  const bodyFormData = new FormData();
  bodyFormData.append('action' , 'showshoppingcart');
  bodyFormData.append('__EVENTARGUMENT' , 'Action=RecalculateCart');
  bodyFormData.append('LayoutNotIncluded' , '1');
  bodyFormData.append('ajax', '1');
  CartItems.map(catrItem => {
    bodyFormData.append(`CartItem_${catrItem.IDCartItem}` , catrItem.Count);
  })
  return axios({
    method: 'post',
    url: '/inshop/scripts/shop.aspx',
    data: bodyFormData,
    config: { headers: {'Content-Type': 'multipart/form-data' }}
  })
}
