import $ from 'jquery'
require('jquery-validation');

$.validator.addMethod("emailordomain", function(value, element) {
    return this.optional(element) || /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(value) || /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/.test(value);
}, "Please specify the correct url/email");

const $form = $('.CheckoutCustomerForm')

$form.validate({
    errorPlacement: function(error, element) {
    },
    highlight: function (element) {
        $(element).parent().removeClass('valid').addClass('error');
    },
    unhighlight: function (element) {
        $(element).parent().removeClass('error').addClass('valid');
    },
    invalidHandler: function(form, validator) {
        console.log(validator.numberOfInvalids());
        if (!validator.numberOfInvalids())
            return;

        // $('html, body').animate({
        //     scrollTop: $(validator.errorList[0].element).offset().top
        // }, 1000);

    },

    rules : {
      'deliveryaddress.Email' : {
        emailordomain : true
      }
    },

    submitHandler: function(form) {
        form.submit()
		    return false;
    }
})
