import { h} from 'preact';
const HeaderCart = ({CartItems, CartTotalPrice}) => {

  return (
    <div>
        <ul className="minicart-dropdown__list">
        {CartItems.map(CartItem => (
          <li className="minicart-dropdown__list--item">
            <div className="minicart__product">
              <div className="minicart__product--left">
                <div className="thumb"><img src={CartItem.photo} /></div>
                <div className="content">
                  <a href="" className="small-font">{CartItem.name}</a>
                  <span className="counter small-font pink" dangerouslySetInnerHTML={{__html : `${CartItem.Count}x ${CartItem.PriceWithVatForRequiredAmount}` }} ></span>
                </div>
              </div>
              <div className="minicart__product--right">
                <a href={`scripts/shop.aspx?action=deletecartitem&amp;IDCartItem=${CartItem.IDCartItem}`} className="btn btn-remove btn-link"><svg width="19" height="19" dangerouslySetInnerHTML={{__html : '<use xlink:href="/inshop/Layout/Pages/src/img/icons/remove.svg#icon-remove"></use>' }} ></svg></a>
              </div>
            </div>
          </li>
        ))}



    </ul>

    <div className="minicart-gratis">
      <p className="pink minicart-gratis-title">{!CartTotalPrice.FreeShiping ? <strong>Už jen kousek a máš dopravu zdarma!</strong> : <strong>Doprava zdarma!</strong>}</p>
      <div className="progress white">
          <div className="progress-bar" role="progressbar" style={{width : !CartTotalPrice.FreeShiping ? `${CartTotalPrice.Percentage}%` : '100%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div className="minicart-gratis__footer">
        <p className="small-font">Doprava zdarma od 3 000 Kč</p>
        {!CartTotalPrice.FreeShiping && <p className="small-font">Zbývá {CartTotalPrice.PriceToFree}</p>}
      </div>
    </div>

    <div className="minicart-footer">
      <p className="pink big-font"><strong dangerouslySetInnerHTML={{__html : `Cena celkem ${CartTotalPrice.PriceWithVat}`  }}></strong></p>
      <a href="/inshop/scripts/shop.aspx?action=showshoppingcart" className="btn btn-primary" type="button">přejít do košíku</a>
    </div>
  </div>
  )
}

export default HeaderCart
