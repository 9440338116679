import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';




if (document.documentMode) {
  document.documentElement.className += ' ie' + document.documentMode;
}


$('.preventDef').on('click', function(e) {
    e.preventDefault();
})

$('#login-btn').on('click', function() {
  document.getElementById("login-btn").classList.toggle("open");
})

$("#servisLink").on('click', function (e) {
  if ($( window ).width() < 768) {
    $(this).parent().toggleClass('open');
  }
  e.preventDefault();
})


function minicart() {
  document.getElementById("minicart-btn").classList.toggle("open");
}



$(document).ready(function () {

  $('#main-menu-btn').on('click', function(e) {
    $(this).toggleClass('open');
    $('.main-header__menu').toggleClass('open');
  });

  $('.mega-dropdown__toggle').on('click', function(e) {
      $(this).parent().toggleClass('open');
  });

  $('.megamenu__title--toggle').on('click', function(e) {
    $(this).parent().toggleClass('open');
  });

  $('#minicart-btn').on('click', function(e) {
    $(this).toggleClass('open');
  });

  $('#showFilter').on('click', function(e) {
    $(this).addClass('open');
    $('.sidebar').addClass('open');
  });

  $('#hideFilter').on('click', function(e) {
    $('.sidebar').removeClass('open');
    $('#showFilter').removeClass('open');
  });

  $('.mainmenu__nav--item').hover(
    function() {
      $(this).toggleClass('open');
    }
  );

  $('#btnAddToCart').on('click', function(e) {
    event.preventDefault();
    $('.modal-cart').addClass('open');
  });

  $('#modalClose').on('click', function(e) {
    $(['.modal-cart','.modal-password']).removeClass('open');
  });

  $('#modalPassword').on('click', function(e) {
    event.preventDefault();
    $('.modal-password').addClass('open');
  });


  $('.sidebar__box--list .level-1.has-children .collapse').on('click', function(e) {
    event.preventDefault();
    $(this).parent().toggleClass('open');
  });


  var $radioButtons = $('input[type="radio"]');
  $radioButtons.click(function() {
      $radioButtons.each(function() {
          $(this).closest('.checkout-group__item--top').toggleClass('open', this.checked);
      });
  });

  // const productTabHeight = $('.main-product__tabs .content').outerHeight();
  // $('.main-product__tabs .content-container').css({'height': productTabHeight});





  $('.carousel__right').on('click', '.carousel__right--item', function() {
    var $this = $(this);
    var slideNum = $(this).data('slide');
    $(".homepage-carousel").trigger('owl.goTo', slideNum);
  });

  function moved() {
    var owl = $(".homepage-carousel").data('owlCarousel');
    var $buttons = $('.carousel__right .carousel__right--item');
    $buttons.removeClass('active').removeAttr('disabled');
    $('.carousel__right').find('[data-slide="'+owl.currentItem +'"]').addClass('active').attr('disabled', 'disabled');
  }

  $('.homepage-carousel').owlCarousel({
    margin:0,
    // loop:true,
    // autoWidth:true,
    autoPlay:true,
    singleItem: true,
    pagination: false,
    rewindSpeed: 500,
    afterMove: moved
  });



  var targets = $( '[rel~=tooltip]' ),
      target  = false,
      tooltip = false,
      tip = false,
      title   = false;

  targets.bind( 'mouseenter', function()
  {
      target  = $( this );
      tip     = target.attr( 'title' );
      tooltip = $( '<div id="tooltip"></div>' );

      if( !tip || tip == '' )
          return false;

      target.removeAttr( 'title' );
      tooltip.css( 'opacity', 0 )
             .html( tip )
             .appendTo( 'body' );

      var init_tooltip = function()
      {
          if( $( window ).width() < tooltip.outerWidth() * 1.5 )
              tooltip.css( 'max-width', $( window ).width() / 2 );
          else
              tooltip.css( 'max-width', 340 );

          var pos_left = target.offset().left + ( target.outerWidth() / 2 ) - ( tooltip.outerWidth() / 2 ),
              pos_top  = target.offset().top - tooltip.outerHeight() - 20;

          if( pos_left < 0 )
          {
              pos_left = target.offset().left + target.outerWidth() / 2 - 20;
              tooltip.addClass( 'left' );
          }
          else
              tooltip.removeClass( 'left' );

          if( pos_left + tooltip.outerWidth() > $( window ).width() )
          {
              pos_left = target.offset().left - tooltip.outerWidth() + target.outerWidth() / 2 + 20;
              tooltip.addClass( 'right' );
          }
          else
              tooltip.removeClass( 'right' );

          if( pos_top < 0 )
          {
              var pos_top  = target.offset().top + target.outerHeight();
              tooltip.addClass( 'top' );
          }
          else
              tooltip.removeClass( 'top' );

          tooltip.css( { left: pos_left, top: pos_top } )
                 .animate( { top: '+=10', opacity: 1 }, 50 );
      };

      init_tooltip();
      $( window ).resize( init_tooltip );

      var remove_tooltip = function()
      {
          tooltip.animate( { top: '-=10', opacity: 0 }, 50, function()
          {
              $( this ).remove();
          });

          target.attr( 'title', tip );
      };

      target.bind( 'mouseleave', remove_tooltip );
      tooltip.bind( 'click', remove_tooltip );
  });
  });
