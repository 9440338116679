import { h, render, Component } from 'preact';
import {getCartJson, recalculateCart} from './actions/cart-actions'
import Loading from './cart-components/loading'
import TrackWarning from './cart-components/track-warining'
import Features from './cart-components/features'
import Table from './cart-components/table'
import CartFooter from './cart-components/cart-footer'
import HeaderCart from './cart-components/header-cart'


class Cart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      CartItems: false,
      fetching : false,
      CartTotalPrice : false,
      fetched : false
    };
    this.recalculateCart = this.recalculateCart.bind(this)
    this.recalculateHeaderCart = this.recalculateHeaderCart.bind(this)
  }

  componentDidMount() {
    // $('#cartHeader').html('')
    // render(<h2>renderer</h2>, document.getElementById('cartHeader'));
  }

  componentWillMount() {
    this.setState({
      fetching : true
    })
    getCartJson().then(res => {
      this.setState({
        CartItems : res.data.CartItems,
        CartTotalPrice : res.data.CartTotalPrice,
        fetching : false,
        fetched : true
      })
      console.log(res.data);
    })
  }

  recalculateHeaderCart(CartItems, CartTotalPrice){
      $('#minicart-btn .minicart__content').html(CartTotalPrice.PriceWithVat)
       $('#headerCartList').html('')
      render(<HeaderCart CartItems={CartItems} CartTotalPrice={CartTotalPrice} />, document.getElementById('headerCartList'));
  }

  recalculateCart(CartItem){
    this.setState({
      fetching : true
    })
    const {state : {CartItems}, recalculateHeaderCart} = this
    const index =  CartItems.findIndex(_item => _item.IDCartItem == CartItem.IDCartItem);
    CartItems[index] = CartItem
    recalculateCart(CartItems).then(res => {
      recalculateHeaderCart(res.data.CartItems, res.data.CartTotalPrice)
      this.setState({
        CartItems : res.data.CartItems,
        CartTotalPrice : res.data.CartTotalPrice,
        fetching : false
      })
    })

  }

  render(){
    const {state : {CartItems, CartTotalPrice, fetching, fetched}, recalculateCart} = this
    let _trackWarning = false
    if (CartItems) {
      CartItems.map(item => {
        if (item.manufacturer === 'TREK') {
          _trackWarning = true
        }
      })
    }

    return (
      <div style={{position : 'relative'}}>
          {fetching && <Loading/> }
          {fetched &&
            <div>
            {CartItems.length > 0 ?
              <div>
                <h1 className="main-cart__content--title">Obsah košíku</h1>
                {_trackWarning && <TrackWarning />}
                <Features />
                {CartItems && <Table  CartItems={CartItems} recalculateCart={recalculateCart} />}
                <CartFooter CartTotalPrice={CartTotalPrice} />
              </div> :
              <div>
                <h1 className="main-cart__content--title">V košíku nejsou žádné položky</h1>
              </div>
            }
            </div>

          }

      </div>
    )
  }
}

const cartId =  document.getElementById('cart') 
cartId && render(<Cart />, document.getElementById('cart'));
