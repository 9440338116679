import { h , Component} from 'preact';

function isNumberKey(evt)
  {
    var e = evt || window.event; //window.event is safer, thanks @ThiefMaster
    var charCode = e.which || e.keyCode;
    if (charCode > 31 && (charCode < 47 || charCode > 57))
    return false;
    if (e.shiftKey) return false;
    return true;
 }

class CartItem extends Component {
  constructor(props) {
    super(props);
    this.countChange = this.countChange.bind(this)
    this.state = {
      Count : this.props.cartItem.Count
    }
  }

  countChange(e){
    const isNuber = isNumberKey(e) ? true :  false
    const {state : {Count}} = this
    if (isNuber) {
      if (e.target.value > 0 ) {
        this.setState({
          Count : e.target.value
        })
        const {props : {recalculateCart, cartItem}} = this
        cartItem.Count = e.target.value
        recalculateCart(cartItem)
      }
      else{
        this.setState({
          Count : Count
        })
      }
    }
    else{
      this.setState({
        Count : Count
      })
    }



  }


  render(){
    const {props : {cartItem}, state : {Count}, countChange} = this
    console.log(Count);
    return (
      <tr className="cart__table--row">
                <td className="cart__table--body-cell">

                  <div className="cart__table--product">
                    <div className="cart__table--thumb"> <img src={cartItem.photo} alt={cartItem.name}/></div>
                    <a href={cartItem.url}>{cartItem.name}</a>

                  </div>
                </td>
                <td className="cart__table--body-cell">


                  <div className="form-group counter cart__table--right">
                      <input className="form-control counter__input" onKeyUp={countChange} value={Count} type="text"/>
                    <span className="counter__unit">ks</span>
                  </div>
                </td>
                <td className="cart__table--body-cell mobile-hide">


                  <div className="cart__table--price cart__table--right">
                    <span className="mobile-visible small-font">Cena bez DPH</span>
                    <span dangerouslySetInnerHTML={{__html : cartItem.PriceForRequiredAmount }}></span>
                  </div>
                </td>
                <td className="cart__table--body-cell">


                  <div className="cart__table--price pink cart__table--right">
                    <span className="mobile-visible small-font">Cena s DPH</span>
                    <span dangerouslySetInnerHTML={{__html : cartItem.PriceWithVatForRequiredAmount }}></span>
                  </div>
                </td>
                <td className="cart__table--body-cell">


                  <div className="cart__table--center">
                    <a href={`scripts/shop.aspx?action=deletecartitem&IDCartItem=${cartItem.IDCartItem}`} className="btn btn-remove btn-link" aria-label="Close"><svg  width="19" height="19" dangerouslySetInnerHTML={{__html : '<use xlink:href="/inshop/Layout/Pages/src/img/icons/remove.svg#icon-remove"></use>' }}></svg></a>
                  </div>
                </td>
              </tr>
    )
  }
}



const Table = ({CartItems, recalculateCart}) => {
  console.log(CartItems);
  return (
    <table className="main-cart__content--table cart__table">
      <thead className="cart__table--head">
        <tr className="cart__table--row">
          <th className="cart__table--head-cell">Název produktu</th>
          <th className="cart__table--head-cell">Počet kusů</th>
          <th className="cart__table--head-cell">Cena bez DPH</th>
          <th className="cart__table--head-cell">Celková cena</th>
          <th className="cart__table--head-cell">&nbsp;</th>
        </tr>
      </thead>
      <tbody className="cart__table--body">
        {CartItems.map(cartItem => {
          return <CartItem cartItem={cartItem} recalculateCart={recalculateCart} />
        })}

      </tbody>
    </table>
  )


}

export default Table
