import { h} from 'preact';
const Loading = () => (
  <div className="spinner" style={{display : 'flex'}}>
      <div className="spinner__svg">
        <div className="spinner__svg--item"></div>
        <div className="spinner__svg--item"></div>
        <div className="spinner__svg--item"></div>
        <div className="spinner__svg--item"></div>
      </div>
    </div>
)

export default Loading
