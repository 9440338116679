import $ from 'jquery'

const $Registration_DeliveryPart_checkbox = $('input[name="Registration_DeliveryPart"]')
const $Registration_InvoicePart_checkbox = $('input[name="Registration_InvoicePart"]')

const $Registration_InvoicePart = $('#Registration_InvoicePart')
const $Registration_DeliveryPart = $('#Registration_DeliveryPart')

const showRegistration_DeliveryPart = (bool) => {
    if (bool) {
        $Registration_DeliveryPart.show()
    }
    else{
        $Registration_DeliveryPart.hide()
    }
    $Registration_DeliveryPart_checkbox.prop("checked", bool)
}

const showRegistration_InvoicePart = (bool) => {
    if (bool) {
        $Registration_InvoicePart.show()
    }
    else{
        $Registration_InvoicePart.hide()
    }
    $Registration_InvoicePart_checkbox.prop("checked", bool)
}

$Registration_DeliveryPart_checkbox.on('change', function(){
    if ($(this).prop("checked") == true) {
        $Registration_DeliveryPart.show()
    } else {
        $Registration_DeliveryPart.hide()
    }
})

$Registration_InvoicePart_checkbox.on('change', function(){
    if ($(this).prop("checked") == true) {
        $Registration_InvoicePart.show()
    } else {
        $Registration_InvoicePart.hide()
    }
})


$('#klik').on('change', function(){
    if ($(this).prop("checked") == true) {
       $('.RegFirmPart').show();
       $('input[name="RegType"]').val('firmsubmit');
    } else {
        $('.RegFirmPart').hide();
        $('input[name="RegType"]').val('personsubmit');
    }
})


$('input', $Registration_InvoicePart).map((key, input) => {
    if ($(input).val().length > 0) {
        showRegistration_InvoicePart(true)
    }
})
$('input', $Registration_DeliveryPart).map((key, input) => {
    if ($(input).val().length > 0) {
        showRegistration_DeliveryPart(true)
    }
})

$('input[name="Subject.Firm.CompanyName"]').on('change', function(){
    if ($(this).val().length > 0) {
        $('input[name="RegType"]').val('firmsubmit')
    } else {
        $('input[name="RegType"]').val('personsubmit')
    }
})

// if($('input[name="Subject.Firm.CompanyName"]').val().length){
//     $('input[name="RegType"]').val('firmsubmit')
// }