import { h} from 'preact';
const TrackWarning = () => (
  <div className="features">

      <div className="features__box">
      <div className="features__box--left">
      <svg width="40" height="40" dangerouslySetInnerHTML={{__html : '<use  xlink:href="/inshop/Layout/Pages/src/img/icons/reload.svg#reload" ></use>' }} ></svg>
      </div>
      <div className="features__box--right">
      <p>30 dní na vrácení či výměnu<br/>bez udání důvodu</p>
      </div>
      </div>

      <div className="features__box">
      <div className="features__box--left">
      <svg width="40" height="40" dangerouslySetInnerHTML={{__html : '<use xlink:href="/inshop/Layout/Pages/src/img/icons/delivery.svg#delivery" ></use>' }}></svg>
      </div>
      <div className="features__box--right">
      <p>Doprava zdarma<br/>od 3 000 kč</p>
      </div>
      </div>

      <div className="features__box">
      <div className="features__box--left">
      <svg width="40" height="40" dangerouslySetInnerHTML={{__html : '<use xlink:href="/inshop/Layout/Pages/src/img/icons/credit-card.svg#credit-card" ></use>' }}></svg>
      </div>
      <div className="features__box--right">
      <p>Možnost platby kartou<br/>online</p>
      </div>
      </div>

</div>
)

export default TrackWarning
