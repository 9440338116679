import { MDCDialog } from '@material/dialog';
import Cookies from 'cookies-js';

if (EshopParams.page === 'Checkout_Customer') {
    const giftDialog = new MDCDialog(document.querySelector('.dialog-gift'));
    const customerDialog = new MDCDialog(document.querySelector('.dialog-customer-card'));

    const $zakaznickaKartaInput = $('#orderparam_zakaznicka_karta')
    const $slevovyKodPoukazInput = $('#orderparam_slevovy_kod_poukaz')

    function setZakaznickaKartaActionText() {
        console.log($zakaznickaKartaInput.val());

        if ($zakaznickaKartaInput.val().length > 0) {
            $('#setCustomerCart').text('Upravit')
        } else {
            $('#setCustomerCart').text('Vyplnit')
        }
    }

    function setSlevovyKodPoukazText() {
        console.log($slevovyKodPoukazInput.val());
        if ($slevovyKodPoukazInput.val().length > 0) {
            $('#setSlevovyKodPoukaz').text('Upravit')
        } else {
            $('#setSlevovyKodPoukaz').text('Vyplnit')
        }
    }

    $('#slevovy_kod_poukaz_text').text($slevovyKodPoukazInput.val())
    setSlevovyKodPoukazText()

    $('#orderparam_zakaznicka_karta_text').text($zakaznickaKartaInput.val())
    setZakaznickaKartaActionText()

    $('#setCustomerCart').on('click', function(e) {
        e.preventDefault();
        customerDialog.open();
    })

    $('#setSlevovyKodPoukaz').on('click', function(e) {
        e.preventDefault();
        giftDialog.open()
    })

    //giftDialog.open();
    $('#orderparam_zakaznicka_karta').keyup(function(event) {
            $('#orderparam_zakaznicka_karta_text').text($(this).val())
            setZakaznickaKartaActionText()
        })
        //customerDialog.open();
    $('#orderparam_slevovy_kod_poukaz').keyup(function(event) {
        $('#slevovy_kod_poukaz_text').text($(this).val())
        setSlevovyKodPoukazText()
    })
}


$('.checkout__login--title a').on('click', function(e) {
    //  e.preventDefault();
    Cookies.set('redirect2checkout', 'true', { expires: 600 });
})




// document.querySelector('#dialog-login').addEventListener('click', function (evt) {
//   event.preventDefault(evt);
//   dialog.lastFocusedTarget = evt.target;
//   // This shows all dialogs, which is wrong.
//
// })

$('.DeliveryPaymentForm .checkout-group select').on('change', function() {
    const deliveryId = $(this).attr('data-delivery-method-id')
    $('input[name=OrderDeliveryMethod][value=' + deliveryId + ']').prop("checked", true);
    $('input[name="action"]').val('ShowWizardCheckout')
    $('input[name="Step"]').val('Delivery')
    $('.DeliveryPaymentForm').submit()
})

$('.DeliveryPaymentForm').on('submit', function() {
    $('#checkoutSpinner').css('display', 'flex');
})

$('select[name="TargetCountry"]').on('change', function() {
    $('input[name="action"]').val('ShowWizardCheckout')
    $('input[name="Step"]').val('Delivery')
    $('.DeliveryPaymentForm').submit()
})

const $buyAsFirmCheckbox = $('input[name="RegType"]')
const $buyAsfirm = $('#buyAsfirm, #buyAsfirmotherInvoice')
const buyAsfirm = () => {
    if ($buyAsFirmCheckbox.is(':checked')) {
        $buyAsfirm.show()
    } else {
        $buyAsfirm.hide()
    }
}

buyAsfirm()
$buyAsFirmCheckbox.on('change', function() {
    buyAsfirm()
})


const $otherInvoiceCheckbox = $('input[name="invoiceaddress.IsSpecificAddress"]')
const $otherInvoice = $('#otherInvoice')
const $deliveryInvoiceH2 = $('#deliveryInvoiceH2')
const otherInvoice = () => {
    if ($otherInvoiceCheckbox.is(':checked')) {
        $deliveryInvoiceH2.html(EshopParams.deliveryOtherInvoiceH2)
        $otherInvoice.show()
    } else {
        $deliveryInvoiceH2.html(EshopParams.deliveryInvoiceH2)
        $otherInvoice.hide()
    }
}

otherInvoice()
$otherInvoiceCheckbox.on('change', function() {
    otherInvoice()
})




const $otherDeliveryCheckbox = $('input[name="deliveryaddress.IsSpecificAddress"]')
const $otherDelivery = $('#otherDelivery')
const otherDelivery = () => {
    if ($otherDeliveryCheckbox.is(':checked')) {
        $otherDelivery.show()
    } else {
        $otherDelivery.hide()
    }
}

otherDelivery()
$otherDeliveryCheckbox.on('change', function() {
    otherDelivery()
})


$("#PSCSelect").select2({
    ajax: {
        url: "/inshop/webservices/napostu.asmx/Suggest",
        dataType: 'xml',
        delay: 250,
        data: function(params) {
            return {
                Text: params.term, // search term
                MaxCount: 50
            };
        },
        processResults: function(data, page) {
            var items = [];
            $(data).find('SimplePostOffice').each(function() {
                var item = {
                    id: $(this).find('PSC').text(),
                    text: $(this).find('Nazev').text() + ' ' + $(this).find('Adresa').text()
                }
                items.push(item);
            });
            return {
                results: items
            };
        },
        cache: true
    },
    escapeMarkup: function(markup) {
        return markup;
    },
    minimumInputLength: 1,
});