import { h} from 'preact';
const CartFooter = ({CartTotalPrice}) => {
  let {CartPriceWithVat, PriceWithVat, Price, FreeShiping} = CartTotalPrice


  return (
    <div className="main-cart__content--footer cart__footer">
                        <div className="cart__footer--line">
                            <div className="cart__footer--side minicart-gratis">
                              <p className="pink minicart-gratis-title">{!FreeShiping ? <strong>Už jen kousek a máš dopravu zdarma!</strong> : <strong>Doprava zdarma!</strong>}</p>
                              <div className="progress">
                                  <div className="progress-bar" role="progressbar" style={{width : !FreeShiping ? `${CartTotalPrice.Percentage}%` : '100%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <div className="minicart-gratis__footer">
                                <p className="small-font">Doprava zdarma od 3 000 Kč</p>
                                {!FreeShiping && <p className="small-font">Zbývá {CartTotalPrice.PriceToFree}</p>}

                              </div>
                            </div>
                            <div className="cart__footer--side">
                              <div className="final">
                                <div className="final__left">
                                  <p className="big-font pink"><strong>Celková cena:</strong></p>
                                  <p>Bez DPH:</p>
                                </div>
                                <div className="final__right">
                                  <p className="big-font pink"><strong dangerouslySetInnerHTML={{__html : PriceWithVat }}></strong></p>
                                  <p dangerouslySetInnerHTML={{__html : Price }}></p>
                                </div>
                              </div>

                            </div>

                        </div>
                        <div className="cart__footer--line">
                          <div className="cart__footer--side btns"><a href="" className="btn btn-primary gray">pokračovat v nákupu</a> </div>
                          <div className="cart__footer--side btns">

                              <a href="/inshop/scripts/shop.aspx?action=ShowWizardCheckout&Step=1" className="btn btn-primary">další krok</a>
                          </div>
                        </div>

                      </div>
  )
}

export default CartFooter
