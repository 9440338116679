import $ from 'jquery'
import { MDCDialog } from '@material/dialog';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/tab';
require('jquery-validation');


$('#Enhanced_Description p').removeAttr('style');

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$.extend($.validator.messages, {
    required: "Tento údaj je povinný.",
    remote: "Prosím, opravte tento údaj.",
    email: "Prosím, zadejte platný e-mail.",
    url: "Prosím, zadejte platné URL.",
    date: "Prosím, zadejte platné datum.",
    dateISO: "Prosím, zadejte platné datum (ISO).",
    number: "Prosím, zadejte číslo.",
    digits: "Prosím, zadávejte pouze číslice.",
    creditcard: "Prosím, zadejte číslo kreditní karty.",
    equalTo: "Prosím, zadejte znovu stejnou hodnotu.",
    extension: "Prosím, zadejte soubor se správnou příponou.",
    maxlength: $.validator.format("Prosím, zadejte nejvíce {0} znaků."),
    minlength: $.validator.format("Prosím, zadejte nejméně {0} znaků."),
    rangelength: $.validator.format("Prosím, zadejte od {0} do {1} znaků."),
    range: $.validator.format("Prosím, zadejte hodnotu od {0} do {1}."),
    max: $.validator.format("Prosím, zadejte hodnotu menší nebo rovnu {0}."),
    min: $.validator.format("Prosím, zadejte hodnotu větší nebo rovnu {0}.")
});

var AjaxForm = {
    sendButton: '.ajax-send',
    initialize() {
        return this.setup();
    },

    setup() {
        return $('form[data-ajax-form="true"]').each(function() {
            return AjaxForm.form.call(this);
        });
    },

    form() {
        $("<input>").attr({
            type: 'hidden',
            name: 'action',
            value: $(this).data('action')
        }).appendTo(this);
        return AjaxForm.validate.call(this);
    },

    validate() {
        return $(this).validate({
            errorElement: "div",
            errorClass: "validateError",
            highlight(element, errorClass, validClass) {
                return $(element).closest(".form-group").addClass("missing").removeClass('valid');
            },
            unhighlight(element, errorClass, validClass) {
                return $(element).closest(".form-group").removeClass("missing").addClass('valid');
            },
            submitHandler(form) {
                $('.AjaxFormSpinner').css('display', 'flex');
                $.ajax({
                        url: '/inshop/scripts/shop.aspx?jsonresult=1',
                        type: 'POST',
                        data: $(form).serialize()
                    })
                    .done(function() {
                        $('.AjaxFormSpinner').css('display', 'none');
                        return alert("Formulář byl úspěšně odeslán");
                    });
                return false;
            }
        });
    }
};


AjaxForm.initialize();



// https://www.svorada.inshop.cz/inshop/scripts/shop.aspx?action=ViewTemplate&Path=Tabulky_velikosti

if (EshopParams.page === 'ProductDetailPageable') {

    $('.modal-cart #modalClose').on('click', function(e) {
        e.preventDefault();
        $('.modal-cart').removeClass('open');
    })

    $('.ProductForm .btn').on('click', function(e) {
        event.preventDefault();
        $('#add2cartSpinner').css('display', 'flex');
        $.ajax({
                url: '/inshop/scripts/shop.aspx?LayoutNotIncluded=1',
                type: 'POST',
                data: $('.ProductForm').serialize()
            })
            .done(function(html) {
                $('#add2cartSpinner').css('display', 'none');
                $('.modal-cart .minicart-gratis').html($(html, '.minicart-gratis').html());
                $('.modal-cart').addClass('open');

                $('#cartHeader #minicart-btn .badge').html($('#minicart-btn .badge', html).html());
                $('#cartHeader #minicart-btn .minicart__content').html($('#minicart-btn .minicart__content', html).html());

                $('#headerCartList').html($('#headerCartList', html).html());

            });
        //  $('.modal-cart').addClass('open');
    })



    const sizeDialog = new MDCDialog(document.querySelector('.dialog-sizes'));
    let fetched = false;

    $('#openSizeTable').on('click', function(e) {
        e.preventDefault();
        sizeDialog.open();

        if (!fetched) {
            $.ajax({
                    url: '/inshop/scripts/shop.aspx?action=ViewTemplate&Path=Tabulky_velikosti&LayoutNotIncluded=1',
                    type: 'GET'
                })
                .done(function(html) {
                    $('#sizeContent').html(html)
                    $('#sizeSpinner').hide();
                    fetched = true;
                })
                .fail(function() {
                    console.log("error");
                })
                .always(function() {
                    console.log("complete");
                });
        }



    })

}