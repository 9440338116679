import '../components/wnumb'
import { URLToArray, ArrayToURL, removeURLParameter } from '../components/catalog'

if (EshopParams.page === "Catalogue") {

    $('.main-catalog__right--excerpt span').removeAttr("style");

 

    const scrollAjax = () => {
        const $mainCatalog = $('.main-catalog')
        const scrollTo = ($mainCatalog.height() / 2)

        $('html, body').animate({
            scrollTop: scrollTo
        }, 100);
    }



    const Catalogue = {
        AjaxReady: () => {
            initPriceFilter()
            $('html, body').animate({
                scrollTop: $('.catalog-filters__line').offset().top
            }, 100, function() {
                $('#catalogSpinner').hide();
            });

        },
        HideEmpty : () => {
            const $params74 = $('#AjaxFilters', document);
            $('li', $params74).map((kex, li) => {
                if($('input', li).prop('disabled')){
                    $(li).hide();
                }
            })

        },
         AjaxStart: () => {
            $('#catalogSpinner').css('display', 'flex');
            scrollAjax()
        },

        ajaxReloadContent: (html) => {
            $('#ajaxCatalogue').html($('#ajaxCatalogue', html).html());
            $('#AjaxFilters').html($('#AjaxFilters', html).html());
            Catalogue.HideEmpty();
        },

        ajax: () => {
            const params = $('#AjaxFilters form').serialize();
            Catalogue.AjaxStart()
            $.ajax({
                type: 'POST',
                url: '/inshop/scripts/shop.aspx',
                data: params,
                success: function(html, extstatus, xhrreq) {
                    // params
                    var newUrl = xhrreq.getResponseHeader('x-friendlyfilter-url');
                    window.history.pushState('', '', newUrl);
                    Catalogue.ajaxReloadContent(html)
                }
            }).done(function() {
                Catalogue.AjaxReady()
            });
        },

        ajaxGet: (url) => {
            Catalogue.AjaxStart()
            $.ajax({
                type: 'GET',
                url: url,
                success: function(html, extstatus, xhrreq) {
                    if (url.search("LayoutNotIncluded") > 0) {
                        url = url.replace("&LayoutNotIncluded=1", "");
                    };
                    if (url.search("rerenderpatams") > 0) {
                        url = url.replace("&rerenderpatams=true", "");
                    };
                    window.history.pushState('', '', url);
                    Catalogue.ajaxReloadContent(html)

                }
            }).done(function() {
                Catalogue.AjaxReady()
            });
        }




    }

    Catalogue.HideEmpty();

    $('#AjaxFilters').on('change', 'input, select', function() {
        Catalogue.ajax()
    })

    $('body').on('click', '.pagination__list a, #pageSize a, .sorting a, #sortType a', function(event) {
        event.preventDefault();
        const url = $(this).attr('href');
        Catalogue.ajaxGet(url)
    })

    let delayTimer;
    const initPriceFilter = () => {

        const slider = document.getElementById('priceRange');
        $('#priceFilter').hide();
        var rangeMin = parseInt($('#CatalogMinPrice').val());
        var rangeMax = parseInt($('#CatalogMaxPrice').val());

        var minInput = $('input[name="minPriceWithVat"]');
        var maxInput = $('input[name="maxPriceWithVat"]');

        console.log('rangeMin', rangeMin);
        console.log('rangeMax', rangeMax);

        console.log('minInput', minInput.val());
        console.log('maxInput', maxInput.val());

        if (minInput.val()) {
            var minPrice = parseInt(minInput.val().replace(" ", ""))
        } else {
            var minPrice = rangeMin;
        }

        if (maxInput.val()) {
            var maxPrice = parseInt(maxInput.val().replace(" ", ""));
        } else {
            var maxPrice = rangeMax;
        }

        const _format = wNumb({
            decimals: 0,
            thousand: ' ',
        })

        if (!isNaN(rangeMin)) {
            $('#priceFilter').show();
            noUiSlider.create(slider, {
                connect: true,
                start: [minPrice, maxPrice],
                step: 100,
                tooltips: false,
                range: { 'min': rangeMin, 'max': rangeMax },
                format: _format
            });
            $('#rangeSliderValueMin').text(_format.to(minPrice))
            $('#rangeSliderValueMax').text(_format.to(maxPrice))


            slider.noUiSlider.on('slide', function(values, handle) {
                $('#rangeSliderValueMin').text(values[0])
                $('#rangeSliderValueMax').text(values[1])
            })

            slider.noUiSlider.on('change', function(values, handle) {
                console.log(values);
                clearTimeout(delayTimer)
                var _minPrice = values[0].replace(/\s/g, '')
                var _maxPrice = values[1].replace(/\s/g, '')

                minInput.val(values[0]);
                maxInput.val(values[1]);


                var url = window.location.href;
                var _params = window.location.search
                var uri = window.location.toString();
                var clean_uri = window.location.toString()

                if (uri.indexOf("?") > 0) {
                    var clean_uri = uri.substring(0, uri.indexOf("?"));
                }


                var urlParamsArray = URLToArray(_params)
                console.log(urlParamsArray)

                urlParamsArray['MAXPRICEWITHVAT'] = _maxPrice
                urlParamsArray['MINPRICEWITHVAT'] = _minPrice
                urlParamsArray['pageindex'] = 1






                window.history.pushState('', '', clean_uri + '?' + ArrayToURL(urlParamsArray));

                // var match = window.location.href.search("MAXPRICEWITHVAT");
                // var separator = window.location.href.search('\\?');
                // if (separator < 0) {
                //   separator = '?';
                // }else{
                //   separator = '&';
                // };

                delayTimer = setTimeout(function() {
                    Catalogue.ajax()
                }, 1000)


            });
        }







    }

    initPriceFilter()
}